import Slide from '@mui/material/Slide';
import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// blue color
export const blue = "#232f3e";
// blue color
export const red = "#f94141";
// secondary
export const secondary = "#B31942";
// green color
export const green = "#00C938";
// yellow
export const yellow = "#ff9900";
// greyInputLabel
export const greyInputLabel = "rgba(0, 0, 0, 0.6)";
// black
export const black = "rgba(0, 0, 0, 0.87)";
// primary
export const primary = "rgb(70, 67, 220)";


export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  export const useGetUrl = (BlogData) => {
    const location = useLocation();
  
    useEffect(() => {
      const url = location.pathname.split("/")[1];
      BlogData(url); 
    }, [location, BlogData]); 
  };