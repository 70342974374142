import React from 'react';
import ReactDOM from 'react-dom/client';
import './rtl.css';
import App from './App';
import { ThemeProvider } from '@mui/material';
import { createTheme } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const theme = createTheme({
  palette: {
    primary: {
      main: '#4643DC'
    }
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <ThemeProvider theme={theme}>
       <App />
    </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

