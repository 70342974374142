import { createSlice } from "@reduxjs/toolkit";
import { MainTextEnglish, MainTextArabic } from "./languageConst";
const initialState = {
  languageType: localStorage.language === "arabic" ? MainTextArabic : MainTextEnglish,
  language: localStorage.language || "english",
  direction:localStorage.direction === "rtl" ? 'rtl' : 'ltr',
  // direction: localStorage.direction || "ltr",
};
const LanguageReducer = createSlice({
  name: "LanguageReducer",
  initialState,
  reducers: {
    updateLanguage: (state, action) => {
      state.language = action.payload;
      if (action.payload === "arabic") {
        state.languageType = MainTextArabic;
         state.direction = 'rtl'
      } else {
        state.languageType = MainTextEnglish;
        state.direction = 'ltr'
      }
    },
  },
});
export const { updateLanguage } = LanguageReducer.actions;
export default LanguageReducer.reducer;
