import { Box } from "@mui/material";
import { Circles } from "react-loader-spinner";
 import { primary } from "../../../utils/helper";
const FallbackLoader = (props) => {
  
  return (
    <Box className={"loader"} sx={{height:"100vh"}}>
      <Circles type="Circles" width={50} height={50} color={primary} 
      />
    </Box>
  );
};

export default FallbackLoader;
